import { AppBar, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, InputAdornment, Slide, Switch, TextField, Toolbar, Typography } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { MuiFileInput } from "mui-file-input";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { executeAlert } from "../../../../../../application/states/toast-alert";
import { format } from "date-fns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { darkenColor, dataGridTexts, formatCep, formatCnpj, formatTelefoneBrazil, onlyNumbers } from "../../../../../../utils";
import { useAppDispatch, useAppSelector } from "../../../../../../application/states/hooks";
import { useBackofficeService } from "../../../../../../use-cases/backoffice";
import { useUserService } from "../../../../../../use-cases/user";
import { theme } from "../../../../../styles";
import { WhiteLabelCompanyForm } from "./WhiteLabelCompanyForm";


const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CompanyForm = () => {

  const handleChange = (event: any) => {
    const inputValor = event.target.value;

    // Remover todos os caracteres que não sejam números, ponto decimal ou vírgula
    const valorSemSimbolos = inputValor.replace(/[^0-9.,]/g, '');

    // Verificar se há mais de um ponto decimal ou vírgula
    const matchPontos = valorSemSimbolos.match(/[.,]/g);
    if (matchPontos && matchPontos.length > 1) {
      return; // Valor inválido, não atualizar o estado
    }

    // Substituir a vírgula por ponto para garantir um formato numérico correto
    const valorFormatado = valorSemSimbolos.replace(',', '.');

    setPrice(parseFloat(valorFormatado));
  };

  const handleSeparatedCreditsPriceChange = (event: any) => {
    const inputValor = event.target.value;

    // Remover todos os caracteres que não sejam números, ponto decimal ou vírgula
    const valorSemSimbolos = inputValor.replace(/[^0-9.,]/g, '');

    // Verificar se há mais de um ponto decimal ou vírgula
    const matchPontos = valorSemSimbolos.match(/[.,]/g);
    if (matchPontos && matchPontos.length > 1) {
      return; // Valor inválido, não atualizar o estado
    }

    // Substituir a vírgula por ponto para garantir um formato numérico correto
    const valorFormatado = valorSemSimbolos.replace(',', '.');

    setSeparatedCreditsPrice(parseFloat(valorFormatado));
  };

  // Função para formatar o valor monetário exibido
  const formatarValor = (valor: any) => {
    if (valor) {
      if (valor.split) {
        const partes = valor.split(/[.,]/); // Divide o valor em partes separadas por '.' ou ','
        const inteiro = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Formata a parte inteira com separador de milhar
        const decimal = partes[1] ? partes[1].padEnd(2, '0') : '00'; // Completa até duas casas decimais
        return `${inteiro},${decimal}`;
      }
    }
  };

  const selectedCompany = useAppSelector((state) => state.backofficeCompany.selectedCompany)
  const { resaleConfig } = useAppSelector((state) => state.backofficeCompany.selectedCompanyResaleConfig)
  const userState = useAppSelector((state) => state.user)
  const open = useAppSelector((state) => state.backofficeCompany.companyForm.open)
  const companyFormFields = useAppSelector((state) => state.backofficeCompany.companyForm.formFields)
  const selectedSeller = useAppSelector((state) => state.user.selectedSeller)
  const file = useAppSelector((state) => state.backofficeCompany.companyForm.imageFile)
  const company = useAppSelector((state) => state.company.company)
  const subscription = useAppSelector((state) => state.subscription)
  const dispatch = useAppDispatch()
  const [min, setMin] = useState(0)

  useEffect(() => {
    if (selectedCompany?.subscription.ranges.length === 0) {
      setMin(0)
    } else {
      let maxPrev = selectedCompany?.subscription.ranges[selectedCompany?.subscription.ranges.length - 1]?.max
      if (maxPrev) {
        setMin(maxPrev + 1)
      }
      setFixedPrice(false)
    }

  }, [selectedCompany?.subscription.ranges])

  const [max, setMax] = useState<number | undefined>(undefined)

  const [price, setPrice] = useState<number | undefined>(undefined)

  const [separatedCreditsValue, setSeparatedCreditsValue] = useState<number | undefined>(undefined)
  const [separatedCreditsPrice, setSeparatedCreditsPrice] = useState<number | undefined>(undefined)
  const [expDate, setExpDate] = useState<Date | null>(null)
  const { selectSeller } = useUserService()
  const {
    selectCompany,
    feedCompanyForm,
    closeCompanyForm,
    feedAddressFields,
    createCompany,
    updateCompany,
    setCompanyFileImage,
    addSeparatedCredits,
    resetSelectedCompanyResaleConfig
  } = useBackofficeService()

  const separatedCreditsColumns: GridColDef[] = [
    {
      field: 'total',
      headerName: 'Total',
      headerClassName: 'headerStyles',
      flex: 1,
      headerAlign: 'center',
      align: 'center', renderCell(params) {
        return params.value?.toLocaleString('pt-BR')
      }
    },
    {
      field: 'price',
      headerName: 'Preço',
      headerClassName: 'headerStyles',
      flex: 1,
      headerAlign: 'center',
      align: 'center', renderCell(params) {
        return `R$ ${params.value?.toLocaleString('pt-BR', {
          minimumFractionDigits: params.value < 1 ? 4 : 2,
        })}`
      }
    },
    {
      field: 'createdAt',
      headerName: 'Data de criação',
      headerClassName: 'headerStyles',
      flex: 1,
      headerAlign: 'center',
      align: 'center', renderCell(params) {
        return format(new Date(params.value), 'dd/MM/yyyy')
      }
    },
    {
      field: 'expDate',
      headerName: 'Data de expiração',
      headerClassName: 'headerStyles',
      flex: 1,
      headerAlign: 'center',
      align: 'center', renderCell(params) {
        return format(new Date(params.value), 'dd/MM/yyyy')
      }
    }
  ]

  const rangeColumns: GridColDef[] = [
    {
      field: 'delete',
      headerName: 'Excluir',
      headerClassName: 'headerStyles',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        /* Only render this button if the row is the last one */
        if (params.value === true) {
          return (
            <Button
              disabled={userState.userType === 'SELLER'}
              endIcon={<DeleteIcon />}
              onClick={() => {
                if (selectedCompany) {
                  selectCompany({
                    company: {
                      ...selectedCompany.company
                    },
                    subscription: {
                      ...selectedCompany.subscription,
                      ranges: selectedCompany.subscription.ranges.filter((range, index) => {
                        return index !== params.row.id
                      })
                    },
                    separatedCredits: selectedCompany.separatedCredits,
                    resaleConfig: resaleConfig
                  })
                }
              }}>
              Excluir
            </Button>
          )
        } else {
          return null
        }
      }
    },
    {
      field: 'min',
      headerName: 'Mínimo',
      headerClassName: 'headerStyles',
      flex: 1,
      headerAlign: 'center',
      align: 'center', renderCell(params) {
        return params.value?.toLocaleString('pt-BR')
      }
    },
    {
      field: 'max',
      headerName: 'Máximo',
      headerClassName: 'headerStyles',
      flex: 1,
      headerAlign: 'center',
      align: 'center', renderCell(params) {
        return params.value?.toLocaleString('pt-BR') ?? 'MAX - (999.999.999)'
      }
    },
    {
      field: 'fixedPrice',
      headerName: 'Preço fixo (FEE)',
      headerClassName: 'headerStyles',
      flex: 1,
      headerAlign: 'center',
      align: 'center', renderCell(params) {
        if (params.value) {

          return `R$ ${params.value?.toLocaleString('pt-BR', {
            minimumFractionDigits: params.value < 1 ? 4 : 2,
          })}`
        } else {
          return ''
        }
      }
    },
    {
      field: 'pricePerRegister',
      headerName: 'Preço por registro',
      headerClassName: 'headerStyles',
      flex: 1,
      headerAlign: 'center',
      align: 'center', renderCell(params) {
        if (params.value) {

          return `R$ ${params.value?.toLocaleString('pt-BR', {
            minimumFractionDigits: params.value < 1 ? 4 : 2,
          })}`
        } else {
          return ''
        }
      }
    },
  ]

  const [openRangeForm, setOpenRangeForm] = useState(false);
  const [openSeparatedCredits, setOpenSeparatedCredits] = useState(false);
  const [fixedPrice, setFixedPrice] = useState(false)

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        onClose={() => {
          closeCompanyForm()
          resetSelectedCompanyResaleConfig()
        }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography textTransform={"uppercase"} sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedCompany?.company.id ? 'Editar' : 'Cadastrar'} Empresa
            </Typography>
            <Button autoFocus color="inherit" onClick={() => {
              closeCompanyForm()
              resetSelectedCompanyResaleConfig()
            }}>
              Fechar
            </Button>
          </Toolbar>
        </AppBar>
        <Box p={3}>
          <form>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                  }}
                  color="primary"
                  align="center"
                  variant="h6"
                >
                  Dados da Empresa
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    backgroundColor: 'primary.main',
                    height: '2px',
                    width: '50%',
                    margin: '0 auto'
                  }}
                />
              </Grid>
              <Grid container spacing={3} my={2}>
                <Grid item xs={12}>
                  <TextField
                    disabled={userState.userType === 'SELLER'}
                    value={formatCnpj(selectedCompany?.company.cnpj ?? '')}
                    error={!companyFormFields.cnpj.isValid}
                    helperText={!companyFormFields.cnpj.isValid ? companyFormFields.cnpj.error?.message : ''}
                    onChange={(e) => {
                      const formattedCnpj = onlyNumbers(e.target.value)
                      if (selectedCompany) {
                        selectCompany({
                          company: {
                            ...selectedCompany.company,
                            cnpj: formattedCnpj
                          },
                          subscription: {
                            ...selectedCompany.subscription
                          },
                          separatedCredits: selectedCompany.separatedCredits,
                          resaleConfig: resaleConfig
                        })
                      }
                    }}
                    label="CNPJ"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    type="text"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <Button
                          disabled={userState.userType === 'SELLER'}
                          onClick={() => {
                            feedCompanyForm()
                          }}>
                          <SearchIcon />
                        </Button>
                      </InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={userState.userType === 'SELLER'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={!companyFormFields.razaoSocial.isValid}
                    helperText={!companyFormFields.razaoSocial.isValid ? companyFormFields.razaoSocial.error?.message : ''}
                    value={selectedCompany?.company.razaoSocial}
                    onChange={(e) => {
                      if (selectedCompany) {
                        selectCompany({
                          company: {
                            ...selectedCompany.company,
                            razaoSocial: e.target.value.toUpperCase()
                          },
                          subscription: {
                            ...selectedCompany.subscription
                          },
                          separatedCredits: selectedCompany.separatedCredits,
                          resaleConfig: resaleConfig
                        })
                      }
                    }}
                    label="Razão Social"
                    fullWidth
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={userState.userType === 'SELLER'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={selectedCompany?.company.nomeFantasia}
                    onChange={(e) => {
                      if (selectedCompany) {
                        selectCompany({
                          company: {
                            ...selectedCompany.company,
                            nomeFantasia: e.target.value.toUpperCase()
                          },
                          subscription: {
                            ...selectedCompany.subscription
                          },
                          separatedCredits: selectedCompany.separatedCredits,
                          resaleConfig: resaleConfig
                        })
                      }
                    }}
                    label="Nome Fantasia"
                    fullWidth
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={userState.userType === 'SELLER'}
                    error={!companyFormFields.email.isValid}
                    helperText={!companyFormFields.email.isValid ? companyFormFields.email.error?.message : ''}
                    value={selectedCompany?.company.email}
                    onChange={(e) => {
                      if (selectedCompany) {
                        selectCompany({
                          company: {
                            ...selectedCompany.company,
                            email: e.target.value.replace(' ', '').toLocaleLowerCase()
                          },
                          subscription: {
                            ...selectedCompany.subscription
                          },
                          separatedCredits: selectedCompany.separatedCredits,
                          resaleConfig: resaleConfig
                        })
                      }
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="E-mail"
                    fullWidth
                    type="email"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={userState.userType === 'SELLER'}
                    error={!companyFormFields.phone.isValid}
                    helperText={!companyFormFields.phone.isValid ? companyFormFields.phone.error?.message : ''}
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Telefone"
                    fullWidth
                    type="phone"
                    value={formatTelefoneBrazil(selectedCompany?.company.phone ?? '')}
                    onChange={(e) => {
                      if (selectedCompany) {
                        selectCompany({
                          company: {
                            ...selectedCompany.company,
                            phone: onlyNumbers(e.target.value)
                          },
                          subscription: {
                            ...selectedCompany.subscription
                          },
                          separatedCredits: selectedCompany.separatedCredits,
                          resaleConfig: resaleConfig
                        })
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={userState.userType === 'SELLER'}
                    error={!companyFormFields.address.postalCode.isValid}
                    helperText={!companyFormFields.address.postalCode.isValid ? companyFormFields.address.postalCode.error?.message : ''}
                    label="CEP"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    type="text"
                    value={selectedCompany?.company.address.postalCode ? selectedCompany.company.address.postalCode.length === 8 ? formatCep(selectedCompany.company.address.postalCode) : selectedCompany.company.address.postalCode : ''}
                    onChange={(e) => {
                      if (e.target.value.length > 8) return

                      if (selectedCompany) {
                        selectCompany({
                          company: {
                            ...selectedCompany.company,
                            address: {
                              ...selectedCompany.company.address,
                              postalCode: onlyNumbers(e.target.value)
                            }
                          },
                          subscription: {
                            ...selectedCompany.subscription
                          },
                          separatedCredits: selectedCompany.separatedCredits,
                          resaleConfig: resaleConfig
                        })
                      }
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <Button
                          disabled={userState.userType === 'SELLER'}
                          onClick={() => {
                            feedAddressFields()
                          }}>
                          <SearchIcon />
                        </Button>
                      </InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={userState.userType === 'SELLER'}
                    error={!companyFormFields.address.state.isValid}
                    helperText={!companyFormFields.address.state.isValid ? companyFormFields.address.state.error?.message : ''}
                    value={selectedCompany?.company.address.state}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) => {
                      if (e.target.value.length > 2) return

                      if (selectedCompany) {
                        selectCompany({
                          company: {
                            ...selectedCompany.company,
                            address: {
                              ...selectedCompany.company.address,
                              state: e.target.value.toUpperCase()
                            }
                          },
                          subscription: {
                            ...selectedCompany.subscription
                          },
                          separatedCredits: selectedCompany.separatedCredits,
                          resaleConfig: resaleConfig
                        })
                      }
                    }}
                    label="UF"
                    fullWidth
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={userState.userType === 'SELLER'}
                    error={!companyFormFields.address.city.isValid}
                    helperText={!companyFormFields.address.city.isValid ? companyFormFields.address.city.error?.message : ''}
                    value={selectedCompany?.company.address.city}
                    onChange={(e) => {
                      if (selectedCompany) {
                        selectCompany({
                          company: {
                            ...selectedCompany.company,
                            address: {
                              ...selectedCompany.company.address,
                              city: e.target.value.replace('  ', ' ').trim().toUpperCase()
                            }
                          },
                          subscription: {
                            ...selectedCompany.subscription
                          },
                          separatedCredits: selectedCompany.separatedCredits,
                          resaleConfig: resaleConfig
                        })
                      }
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Cidade"
                    fullWidth
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={userState.userType === 'SELLER'}
                    error={!companyFormFields.address.street.isValid}
                    helperText={!companyFormFields.address.street.isValid ? companyFormFields.address.street.error?.message : ''}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={selectedCompany?.company.address.street}
                    onChange={(e) => {
                      if (selectedCompany) {
                        selectCompany({
                          company: {
                            ...selectedCompany.company,
                            address: {
                              ...selectedCompany.company.address,
                              street: e.target.value.replace('  ', ' ').trim()
                            }
                          },
                          subscription: {
                            ...selectedCompany.subscription
                          },
                          separatedCredits: selectedCompany.separatedCredits,
                          resaleConfig: resaleConfig
                        })
                      }
                    }}
                    label="Logradouro"
                    fullWidth
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={userState.userType === 'SELLER'}
                    error={!companyFormFields.address.neighbourhood.isValid}
                    helperText={!companyFormFields.address.neighbourhood.isValid ? companyFormFields.address.neighbourhood.error?.message : ''}
                    value={selectedCompany?.company.address.neighbourhood}
                    onChange={(e) => {
                      if (selectedCompany) {
                        selectCompany({
                          company: {
                            ...selectedCompany.company,
                            address: {
                              ...selectedCompany.company.address,
                              neighbourhood: e.target.value.replace('  ', ' ').trim().toUpperCase()
                            }
                          },
                          subscription: {
                            ...selectedCompany.subscription
                          },
                          separatedCredits: selectedCompany.separatedCredits,
                          resaleConfig: resaleConfig
                        })
                      }
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Bairro"
                    fullWidth
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={userState.userType === 'SELLER'}
                    error={!companyFormFields.address.houseNumber.isValid}
                    helperText={!companyFormFields.address.houseNumber.isValid ? companyFormFields.address.houseNumber.error?.message : ''}
                    value={selectedCompany?.company.address.houseNumber}
                    onChange={(e) => {
                      if (selectedCompany) {
                        selectCompany({
                          company: {
                            ...selectedCompany.company,
                            address: {
                              ...selectedCompany.company.address,
                              houseNumber: e.target.value
                            }
                          },
                          subscription: {
                            ...selectedCompany.subscription
                          },
                          separatedCredits: selectedCompany.separatedCredits,
                          resaleConfig: resaleConfig
                        })
                      }
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Número"
                    fullWidth
                    type="text"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disabled={userState.userType === 'SELLER'}
                    options={userState.userList.filter((user) => {
                      return user.userType === 'SELLER'
                    }).map((user) => {
                      return {
                        label: user.name,
                        value: user.id
                      }
                    })}
                    value={{
                      label: selectedSeller?.name ??
                        userState.userList.find((user) => {
                          if (selectedCompany?.subscription.sellerId !== '') {
                            return user.id === selectedCompany?.subscription.sellerId
                          }
                          return false
                        })?.name ?? '',
                      value: selectedSeller?.id ?? selectedCompany?.subscription.sellerId ?? ''
                    }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(event: any, newValue) => {
                      if (newValue) {
                        selectSeller({
                          userId: newValue.value
                        })
                      }
                    }}
                    renderInput={(props) =>
                      <TextField
                        {...props}
                        label="Digite ou clique para selecionar um vendedor"
                      />
                    }
                  />
                </Grid>
                {
                  company?.master === true ||
                    (
                      company?.resale === true &&
                      subscription.subscription?.partnerCols === true
                    )
                    ?
                    <Grid item xs={company.master === true ? 3 : 6} display={'flex'} justifyContent={'center'}>
                      <FormGroup sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <FormControlLabel control={<Switch
                          disabled={userState.userType === 'SELLER'}
                          checked={
                            selectedCompany?.subscription.partnerCols
                          }
                          onChange={(e) => {
                            if (selectedCompany) {
                              selectCompany({
                                company: {
                                  ...selectedCompany.company
                                },
                                subscription: {
                                  ...selectedCompany.subscription,
                                  partnerCols: e.target.checked
                                },
                                separatedCredits: selectedCompany.separatedCredits,
                                resaleConfig: resaleConfig
                              })
                            }
                          }
                          }
                        />} label="Colunas de sócio" />
                      </FormGroup>
                    </Grid>
                    : null
                }
                {
                  company?.master === true
                    ?
                    <Grid item xs={3} display={'flex'} justifyContent={'center'}>
                      <FormGroup sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <FormControlLabel control={<Switch
                          disabled={userState.userType === 'SELLER'}
                          checked={selectedCompany?.company.resale}
                          onChange={(e) => {
                            if (selectedCompany) {
                              selectCompany({
                                company: {
                                  ...selectedCompany.company,
                                  resale: e.target.checked
                                },
                                subscription: selectedCompany.subscription,
                                separatedCredits: selectedCompany.separatedCredits,
                                resaleConfig: resaleConfig
                              })
                            }
                          }}
                        />
                        }
                          label="Revenda" />
                      </FormGroup>
                    </Grid>
                    : null
                }
              </Grid>
              {
                selectedCompany?.company.resale === true ?
                    <WhiteLabelCompanyForm />
                  : null
              }
              <Grid item xs={12} mt={1}>
                <Typography
                  sx={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                  }}
                  color="primary"
                  align="center"
                  variant="h6" component="div"
                >
                  Dados do plano
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    backgroundColor: 'primary.main',
                    height: '2px',
                    width: '50%',
                    margin: '0 auto'
                  }}
                />
              </Grid>
              <Grid item xs={12} mt={3}>
                <TextField
                  disabled={userState.userType === 'SELLER'}
                  error={!companyFormFields.subscription.name.isValid}
                  helperText={!companyFormFields.subscription.name.isValid ? companyFormFields.subscription.name.error?.message : ''}
                  value={selectedCompany?.subscription.name}
                  onChange={(e) => {
                    if (selectedCompany) {
                      selectCompany({
                        company: {
                          ...selectedCompany.company
                        },
                        subscription: {
                          ...selectedCompany.subscription,
                          name: e.target.value
                        },
                        separatedCredits: selectedCompany.separatedCredits,
                        resaleConfig: resaleConfig
                      })
                    }
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  label="Nome do plano"
                  fullWidth
                  type="text"
                />
              </Grid>
              {/* <Grid item xs={12} md={2}>
                <FormGroup>
                  <FormControlLabel control={<Switch
                    disabled={userState.userType === 'SELLER'}
                    checked={selectedCompany?.subscription.stackCredits}
                    onChange={(e) => {
                      if (selectedCompany) {
                        selectCompany({
                          company: {
                            ...selectedCompany.company
                          },
                          subscription: {
                            ...selectedCompany.subscription,
                          },
                          separatedCredits: selectedCompany.separatedCredits,
                          resaleConfig: resaleConfig
                        })
                      }
                    }
                    }
                  />} label="Acumular créditos" />
                </FormGroup>
              </Grid> */}

              <Grid item xs={12} md={12}>
                <Grid container spacing={2} style={{
                  marginTop: '30px'
                }}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                      }}
                      color="primary"
                      align="center"
                      variant="h6" component="div"
                    >
                      Ranges de consumo
                    </Typography>
                  </Grid>
                  <Grid item xs={12}
                    sx={{
                      '& .MuiDataGrid-columnHeader': {
                        backgroundColor: theme.palette.primary.main,
                        color: company?.resale === true || company?.resaleId
                          ? theme.palette.primary.contrastText
                          : '#FFF'
                      },
                      '& .MuiDataGrid-cell': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      },
                      '& .MuiDataGrid-cell:focus': {
                        outline: 0
                      },
                      minWidth: '100%'
                    }}
                  >
                    <DataGrid
                      showCellVerticalBorder
                      disableColumnFilter
                      disableColumnMenu
                      rowSelection={false}
                      autoHeight={true}
                      localeText={
                        dataGridTexts
                      }
                      pagination={undefined}
                      rows={selectedCompany?.subscription.ranges.map((range, index) => {
                        return {
                          id: index,
                          min: range.min,
                          max: range.max,
                          fixedPrice: range.fixedPrice,
                          pricePerRegister: range.pricePerRegister,
                          delete: index === selectedCompany?.subscription.ranges.length - 1
                        }
                      }) ?? []
                      }
                      columns={rangeColumns}
                      sx={{
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                          outline: "none !important",
                        },
                        minWidth: '100%'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box my={3}>
                      <Button
                        fullWidth
                        color="primary"
                        onClick={() => {
                          setOpenRangeForm(true)
                        }}
                        disabled={
                          userState.userType === 'SELLER'
                        }
                      >
                        Adicionar range
                      </Button>
                    </Box>

                  </Grid>
                </Grid>
              </Grid>
              {
                selectedCompany?.company.id !== '' ? (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                          }}
                          color="primary"
                          align="center"
                          variant="h6" component="div"
                        >
                          Créditos separados
                        </Typography>
                      </Grid>
                      <Grid item xs={12}
                        sx={{
                          '& .MuiDataGrid-columnHeader': {
                            backgroundColor: theme.palette.primary.main,
                            color: company?.resale === true || company?.resaleId
                              ? theme.palette.primary.contrastText
                              : '#FFF'
                          },
                          '& .MuiDataGrid-cell': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          },
                          '& .MuiDataGrid-cell:focus': {
                            outline: 0
                          },
                          minWidth: '100%'
                        }}
                      >
                        <DataGrid
                          showCellVerticalBorder
                          disableColumnFilter
                          disableColumnMenu
                          rowSelection={false}
                          autoHeight={true}
                          localeText={
                            dataGridTexts
                          }
                          pagination={undefined}
                          rows={
                            selectedCompany?.separatedCredits.map((separatedCredit, index) => {
                              return {
                                id: index,
                                total: separatedCredit.value,
                                price: separatedCredit.price,
                                expDate: separatedCredit.expiresAt,
                                createdAt: separatedCredit.createdAt
                              }
                            }) ?? []
                          }
                          columns={separatedCreditsColumns}
                          sx={{
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                              outline: "none !important",
                            },
                            minWidth: '100%'
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box my={3}>
                          <Button
                            fullWidth
                            disabled={
                              userState.userType === 'SELLER'
                            }
                            color="primary"
                            onClick={() => {
                              setOpenSeparatedCredits(true)
                            }}
                          >
                            Adicionar créditos separados
                          </Button>
                        </Box>

                      </Grid>
                    </Grid>
                  </Grid>
                ) : null
              }
              {
                selectedCompany?.company.id !== '' ? (
                  <Grid item xs={12} mb={2}>
                    <FormGroup>
                      <FormControlLabel control={<Switch
                        disabled={userState.userType === 'SELLER'}
                        checked={selectedCompany?.company.active}
                        onChange={(e) => {
                          if (selectedCompany) {
                            selectCompany({
                              company: {
                                ...selectedCompany.company,
                                active: e.target.checked
                              },
                              subscription: {
                                ...selectedCompany.subscription
                              },
                              separatedCredits: selectedCompany.separatedCredits,
                              resaleConfig: resaleConfig
                            })
                          }
                        }
                        }
                      />
                    }
                      label={selectedCompany?.company.active ? 'Ativa' : 'Inativa'}
                      />
                    </FormGroup>
                  </Grid>
                ) : null
              }
              <Grid item xs={12}>

                <>
                  <MuiFileInput
                    id="outlined-static"
                    value={file}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => {
                      setCompanyFileImage(e)
                    }}
                    label="Clique aqui para adicionar uma imagem"
                    getInputText={(file) => {
                      return file?.name ?? 'Selecione uma imagem'
                    }}
                    InputProps={{
                      inputProps: {
                        accept: '.png, .jpeg, .jpg'
                      }
                    }}
                  />
                  {file !== null ?
                    selectedCompany?.company?.img ?
                      <Grid container p={1} justifyContent={'center'}>
                        <Grid item pl={2} pr={2} pt={3} justifyContent={'center'} maxWidth={250}>
                          <img src={selectedCompany.company?.img} alt="Logo da Empresa" />
                        </Grid>
                      </Grid>
                      : null
                    : null}
                </>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{
                  my: 3
                }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      '&:hover': {
                        backgroundColor: darkenColor(theme.palette.primary.main, 10)
                      },
                      color: theme.palette.primary.contrastText
                    }}
                    onClick={() => {
                      if (selectedCompany) {
                        if (selectedCompany.company.id === '') {
                          createCompany()
                        } else {
                          updateCompany()
                        }
                      }
                    }}
                    disabled={userState.userType === 'SELLER'}
                  >
                    Salvar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>

      </Dialog>
      <Dialog fullWidth maxWidth="lg" open={openRangeForm} onClose={() => {
        setOpenRangeForm(false)
      }}>
        <DialogTitle color="primary" fontWeight={"bold"} textTransform={"uppercase"}>Adicionar novo range</DialogTitle>
        <DialogContent>
          <DialogContentText py={0.5}>
            O range funciona como uma tabela variável de consumo.
          </DialogContentText>
          <DialogContentText py={0.5}>
            Em "Mínimo", você determina a quantidade de créditos que <strong>iniciará</strong> este range.
          </DialogContentText>
          <DialogContentText py={0.5}>
            Em "Máximo", você determina a quantidade <strong>máxima</strong> de créditos que irá compor este range.
          </DialogContentText>
          <DialogContentText py={0.5}>
            Na opção "Preço por registro / Preço fixo", você insere o custo definido para este cliente. Caso esteja selecionada a opção "Preço por registro", você deve inserir o custo por crédito, ou custo por registro cobrado para este cliente.
            Caso a opção selecionada seja "Preço fixo", você insere apenas o custo total determinado com seu cliente para este range.
          </DialogContentText>
          <Grid container spacing={2} style={{
            marginTop: '30px',
            padding: '30px'
          }}>
            <Grid item xs={6}>
              <TextField
                disabled={
                  (selectedCompany?.subscription.ranges[selectedCompany?.subscription.ranges.length - 1]?.max ? true : false) ||
                  (selectedCompany?.subscription.ranges.length === 0 ? true : false)
                }
                fullWidth
                label="Mínimo"
                type="number"
                value={
                  min
                }
                onChange={(e) => {
                  setMin(Number(e.target.value))
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Máximo"
                type="number"
                value={
                  max
                }
                onChange={(e) => {
                  const max = Number(e.target.value)
                  if (max > 0) {
                    setMax(max)
                  } else {
                    setMax(undefined)
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                InputProps={{
                  startAdornment: <Box mr={1}>
                    <Typography>
                      R$
                    </Typography>
                  </Box>
                }}
                fullWidth
                label={
                  fixedPrice ? 'Preço fixo' : 'Preço por registro'
                }
                InputLabelProps={{
                  shrink: true
                }}
                value={formatarValor(price)}
                onChange={handleChange}
              />
            </Grid>
            {
              selectedCompany?.subscription.ranges.length === 0 ? (
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Switch
                      checked={
                        fixedPrice
                      }
                      onChange={(e) => {
                        setFixedPrice(e.target.checked)
                      }}
                    />} label={
                      fixedPrice ? 'Preço fixo' : 'Preço por registro'
                    } />
                  </FormGroup>

                </Grid>
              ) : null
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenRangeForm(false)
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (price === undefined ||
                price === null ||
                price === 0) {
                dispatch(executeAlert({
                  message: 'O preço deve ser maior que 0',
                  type: 'error'
                }))
                return
              }

              if ((selectedCompany?.subscription.ranges.length ?? 0) > 0) {
                let lastRange = { ...selectedCompany?.subscription.ranges[selectedCompany?.subscription.ranges.length - 1] }
                if (!lastRange?.max) {
                  lastRange.max = min - 1
                }

                if (selectedCompany) {
                  if (lastRange) {
                    if (min <= (lastRange.min ?? 0)) {
                      dispatch(executeAlert({
                        message: 'O valor mínimo deve ser maior que o valor mínimo do último range',
                        type: 'error'
                      }))
                      return
                    }

                    if (max && max <= min) {
                      dispatch(executeAlert({
                        message: 'O valor máximo deve ser maior que o valor mínimo',
                        type: 'error'
                      }))
                      return
                    }

                    if (max && max <= lastRange.max) {
                      dispatch(executeAlert({
                        message: 'O valor máximo deve ser maior que o valor máximo do último range',
                        type: 'error'
                      }))
                      return
                    }

                    selectCompany({
                      company: {
                        ...selectedCompany.company
                      },
                      subscription: {
                        ...selectedCompany.subscription,
                        ranges: [
                          ...selectedCompany.subscription.ranges.slice(0, selectedCompany.subscription.ranges.length - 1),
                          {
                            max: lastRange.max,
                            min: lastRange.min ?? 0,
                            pricePerRegister: lastRange.pricePerRegister,
                            fixedPrice: lastRange.fixedPrice,
                          },
                          {
                            max: max,
                            min: min,
                            pricePerRegister: fixedPrice ? undefined : price,
                            fixedPrice: fixedPrice ? price : undefined,
                          }
                        ]
                      },
                      separatedCredits: selectedCompany?.separatedCredits,
                      resaleConfig: resaleConfig
                    })
                  }
                }

              } else {
                if (selectedCompany) {
                  selectCompany({
                    company: {
                      ...selectedCompany.company
                    },
                    subscription: {
                      ...selectedCompany.subscription,
                      ranges: [{
                        min: min,
                        max: max,
                        pricePerRegister: fixedPrice ? undefined : price,
                        fixedPrice: fixedPrice ? price : undefined,
                      }]
                    },
                    separatedCredits: selectedCompany?.separatedCredits,
                    resaleConfig: resaleConfig
                  })
                  setMin(0)
                  setMax(undefined)
                  setPrice(undefined)
                }
              }

              setOpenRangeForm(false)
            }}
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="lg" open={openSeparatedCredits}
        onClose={() => {
          setOpenSeparatedCredits(false)
        }}
      >
        <DialogTitle color="primary" fontWeight={"bold"} textTransform={"uppercase"}>Adicionar créditos separados </DialogTitle>
        <DialogContent>
          <DialogContentText py={0.5}>
            Os créditos separados funcionam como uma espécie de "pacote".
          </DialogContentText>
          <DialogContentText py={0.5}>
            Em "Total", você determina a quantidade total de créditos que serão inseridos para esta empresa. Este será o <strong>limite</strong> de registros.
          </DialogContentText>
          <DialogContentText py={0.5}>
            Em "Preço", voce deve inserir o custo total definido para este pacote de créditos.
          </DialogContentText>
          <DialogContentText py={0.5}>
            Por fim, em "Data de expiração", você deve inserir a data limite em que os usuários desta empresa poderão utilizar os créditos em questão. Você pode digitar uma data no formato DD/MM/AAAA, ou clicar no ícone de calendário e selecionar manualmente.
          </DialogContentText>
          <Grid container justifyContent={"center"} spacing={2}
            style={{
              marginTop: '30px',
              padding: '30px'
            }}>
            <Grid item xs={6}>
            <TextField
    fullWidth
    label="Total"
    type="text" // Alterado para "text" para controle total
    value={separatedCreditsValue ?? ''}
    onChange={(e) => {
      const inputValor = e.target.value;

      // Impedir entrada de caracteres que não sejam números ou ponto
      if (!/^[0-9]*\.?[0-9]*$/.test(inputValor)) {
        if (inputValor.includes(',')) {
          // Alerta específico para vírgulas
          dispatch(executeAlert({
            message: 'Use ponto (.) como separador decimal, vírgula não é permitida.',
            type: 'error'
        }))
        }
        return; // Impede a atualização do estado para entradas inválidas
      }

      // Converte o valor limpo para Number e atualiza o estado
      setSeparatedCreditsValue(Number(inputValor));
    }}
  />
            </Grid>
            <Grid item xs={6}>
              <TextField
                InputProps={{
                  startAdornment: <Box mr={1}>
                    <Typography>
                      R$
                    </Typography>
                  </Box>
                }}
                fullWidth
                label={'Preço'}
                InputLabelProps={{
                  shrink: true
                }}
                value={formatarValor(separatedCreditsPrice)}
                onChange={handleSeparatedCreditsPriceChange}
              />
            </Grid>
            <Grid item>
              <LocalizationProvider
                dateFormats={{ fullDate: 'MM/yyyy' }}
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBR}>
                <DatePicker
                  disablePast
                  value={expDate ? new Date(expDate) : null}
                  onChange={(date) => {
                    if (date) {
                      setExpDate(date)
                    } else {
                      setExpDate(null)
                    }
                  }}
                  label="Data de expiração"
                  slotProps={{
                    day: {
                      sx: {
                        "&.MuiPickersDay-root.Mui-selected": {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                          '&:hover': {
                            backgroundColor: darkenColor(theme.palette.primary.main, 10)
                          }
                        }
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenSeparatedCredits(false)
            }}
          >
            Cancelar
          </Button>
          <Button onClick={() => {
            addSeparatedCredits({
              separatedCredits: {
                value: separatedCreditsValue ?? 0,
                price: separatedCreditsPrice ?? 0,
                expiresAt: expDate
              }
            })
            setOpenSeparatedCredits(false)
          }}
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}