import { useCallback } from "react"
import { IExportLayout } from "../../application/models"
import { resetExportLayoutState, selectExportLayoutState, setExportLayoutActionState, setExportLayoutInputValueState, setExportLayoutOpenModal, setExportLayoutsState } from "../../application/states/export-layout"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { useExportLayoutApi } from "../../infra/export-layout"
import { useUserApi } from "../../infra"
import { executeAlert } from "../../application/states/toast-alert"

export const useExportLayoutService = () => {

  const dispatch = useAppDispatch()
  const { findExportLayoutByCompanyId, updateExportLayoutApi, createExportLayoutApi, deleteExportLayoutApi } = useExportLayoutApi()
  const selectedLayout = useAppSelector((state) => state.exportLayout.selectedLayout)
  const user = useAppSelector((state) => state.user)
  const { getLastDownloadLog } = useUserApi()

  const setExportLayoutInputValue = useCallback((data: {
    inputValue: string
  }) => {
    dispatch(setExportLayoutInputValueState({
      inputValue: data.inputValue
    }))
  }, [dispatch])
  const selectExportLayout = useCallback((data: {
    selectedLayout: IExportLayout
  }) => {
    dispatch(selectExportLayoutState({
      selectedLayout: data.selectedLayout
    }))
  }, [dispatch])

  const openModal = useCallback((data: {
    action: 'create' | 'edit'
  }) => {
    if (data.action === 'create') {
      dispatch(selectExportLayoutState({
        selectedLayout: {
          id: '',
          name: '',
          layoutColumns: [],
          companyId: user.companyId,
          createdAt: new Date(),
          createdBy: user.id,
          updatedAt: new Date(),
        }
      }))
    }

    dispatch(setExportLayoutOpenModal({
      modalOpen: true
    }))
    dispatch(setExportLayoutActionState({
      action: data.action
    }))
  }, [dispatch, user.companyId, user.id])

  const closeModal = useCallback(() => {
    dispatch(setExportLayoutOpenModal({
      modalOpen: false
    }))
  }, [dispatch])

  const setExportLayouts = useCallback(() => {
    if (user.id === '' || !user.id) {
      return
    }
    findExportLayoutByCompanyId({
      companyId: user.companyId
    }).then((response) => {
      dispatch(setExportLayoutsState({
        layouts: response
      }))
      getLastDownloadLog({
        userId: user.id
      }).then((lastDownloadResponse) => {
        if (!lastDownloadResponse) {
          dispatch(selectExportLayoutState({
            selectedLayout: response[0]
          }))
        } else {
          const lastLayoutUsed = response.find((layout) => {
            return layout.id === lastDownloadResponse.layoutId
          })
          if (lastLayoutUsed) {
            dispatch(selectExportLayoutState({
              selectedLayout: lastLayoutUsed
            }))
          } else {
            dispatch(selectExportLayoutState({
              selectedLayout: response[0]
            }))
          }
        }

      })
    })

  }, [
    dispatch,
    findExportLayoutByCompanyId,
    user.companyId,
    getLastDownloadLog,
    user.id
  ])

  const resetExportLayout = useCallback(() => {
    dispatch(resetExportLayoutState())
  }, [dispatch])


  const currentLayouts = useAppSelector((state) => state.exportLayout.layouts); // Obtém o estado atual


  const updateExportLayout = useCallback(() => {
    updateExportLayoutApi({
      id: selectedLayout?.id,
      name: selectedLayout?.name,
      layoutColumns: selectedLayout?.layoutColumns
    }).then((updatedLayout) => {
  
      dispatch(selectExportLayoutState({
        selectedLayout: updatedLayout
      }));
  
      const updatedLayouts = currentLayouts.map((layout: IExportLayout) =>
        layout.id === updatedLayout.id ? updatedLayout : layout
      );
  
      dispatch(setExportLayoutsState({
        layouts: updatedLayouts
      }));
    }).catch((error) => {
      dispatch(executeAlert({
        message: error.message,
        type: 'error'
        }));
    });
  }, [selectedLayout, updateExportLayoutApi, dispatch]);
  

  const createExportLayout = useCallback(() => {
    createExportLayoutApi({
      exportLayout: {
        companyId: user.companyId,
        name: selectedLayout?.name,
        layoutColumns: selectedLayout?.layoutColumns,
        createdBy: user.id
      }
    }).then(() => {
      setExportLayouts()
    })

  }, [createExportLayoutApi, selectedLayout, user.companyId, user.id, setExportLayouts])

  const deleteExportLayout = useCallback(() => {
    deleteExportLayoutApi({
      id: selectedLayout?.id
    }).then(() => {
      setExportLayouts()
    })
  }, [deleteExportLayoutApi, selectedLayout?.id, setExportLayouts])


  return {
    selectExportLayout,
    openModal,
    closeModal,
    setExportLayouts,
    resetExportLayout,
    setExportLayoutInputValue,
    updateExportLayout,
    createExportLayout,
    deleteExportLayout
  }
}